import EnvironmentService from "../utilities/EnvironmentService";

export const API_ROOT =
  window.location.hostname === "localhost"
    ? "https://watch.test.ramseysolutions.com/api/streamingtv"
    : `https://watch.${EnvironmentService.prefix()}ramseysolutions.com/api/streamingtv`;

export const SCA_URL =
  window.location.hostname === "localhost"
    ? "https://subscription.test.ramseysolutions.com/app/subscriptions/new?product=ramsey-network"
    : `https://subscription.${EnvironmentService.prefix()}ramseysolutions.com/app/subscriptions/new?product=ramsey-network`;
