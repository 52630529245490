import classNames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import Button, { VARIANTS } from "react-gazelle/lib/components/Button";
import { Link } from "react-router-dom";

function SiteHeader({ transparent = false }) {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const clearStorageAndLogout = () => {
    localStorage.removeItem("idToken");
    logout();
  };

  const login = () => {
    loginWithRedirect();
  };

  return (
    <div
      className={classNames("SiteHeader in-modeDark", {
        "SiteHeader--transparent": transparent,
      })}
    >
      <Link to="/app/" data-testid="SiteHeader-logo">
        <img
          className="SiteHeader-logo"
          src="https://cdn.ramseysolutions.net/media/b2c/ramsey-network/logos/streamingtv/ramsey-r.svg"
        />
      </Link>

      {isAuthenticated ? (
        <Button
          className="SiteHeader-button"
          onClick={clearStorageAndLogout}
          variant={VARIANTS.GHOST}
        >
          Sign Out
        </Button>
      ) : (
        <Button
          className="SiteHeader-button"
          onClick={login}
          variant={VARIANTS.GHOST}
        >
          Sign In
        </Button>
      )}
    </div>
  );
}

export default SiteHeader;
