import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import classNames from "classnames";
import VideoOverview from "../../components/VideoOverview";
import RecommendedContent from "../../components/RecommendedContent";
import NextSteps from "../../components/NextSteps";
import { SCA_URL, API_ROOT } from "../../constants/network";
import VideoPlayer from "../../components/VideoPlayer";

const Watch = () => {
  const { isAuthenticated, getIdTokenClaims, getAccessTokenSilently } =
    useAuth0();
  const [hasProgress, setHasProgress] = useState(
    isAuthenticated && localStorage.getItem("hasBorrowedFutureStarted")
  );
  const location = useLocation();

  const fetchProgress = async () => {
    if (isAuthenticated) {
      await getAccessTokenSilently().then(async () => {
        await getIdTokenClaims().then(async (data) => {
          const response = await fetch(
            `${API_ROOT}/media/borrowed-future/progress`,
            {
              method: "GET",
              cache: "no-cache",
              headers: {
                Authorization: `Bearer ${data.__raw}`,
                "content-type": "application/json",
              },
            }
          );

          if (response.status === 200) {
            response.json().then((data) => {
              setHasProgress(
                data.progress > 0 ||
                  localStorage.getItem("hasBorrowedFutureStarted")
              );
            });
          }
        });
      });
    } else {
      setHasProgress(false);
    }
  };

  useEffect(() => {
    fetchProgress();
  }, [location, localStorage.hasBorrowedFutureStarted]);

  const fetchStatus = async () => {
    if (isAuthenticated) {
      await getAccessTokenSilently().then(async () => {
        await getIdTokenClaims().then(async (data) => {
          const response = await fetch(
            `${API_ROOT}/authorize/borrowed-future`,
            {
              method: "POST",
              cache: "no-cache",
              headers: {
                Authorization: `Bearer ${data.__raw}`,
              },
            }
          );

          await response.json().then((data) => {
            if (
              localStorage.getItem("redirectToPayments") == "true" &&
              data == "RENT"
            ) {
              localStorage.removeItem("redirectToPayments");
              window.location = SCA_URL;
            }
          });
        });
      });
    }
  };

  useEffect(() => {
    fetchStatus();
  }, [location]);

  if (isAuthenticated) {
    getAccessTokenSilently().then(async () => {
      getIdTokenClaims().then((data) => {
        if (data != undefined) {
          if (window.location.hostname.match(/localhost|test|qa/g) !== null) {
            console.log(data.__raw);
          }
          localStorage.setItem(
            "axid",
            data["https://id.ramseysolutions.com/openid/claims/authx_id"]
          );
        }
      });
    });
  }

  return (
    <div>
      <div
        className={classNames("Watch", {
          "Watch--has-progress": hasProgress,
        })}
      >
        {isAuthenticated && (
          <VideoPlayer itemId="borrowed-future" onWatch={setHasProgress} />
        )}
        {!isAuthenticated && <VideoOverview itemId="borrowed-future" />}

        <NextSteps hasProgress={hasProgress} />
      </div>

      <RecommendedContent hasProgress={hasProgress} />
    </div>
  );
};

export default Watch;
