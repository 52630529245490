class AckbarService {
  static springTheTrap = () => new AckbarService().print();

  print = () => {
    console.log("                            __...------------._");
    console.log("                         ,-'                   `-.");
    console.log("                      ,-'                         `.");
    console.log("                    ,'                            ,-`.");
    console.log("                   ;                              `-' `.");
    console.log("                  ;                                 .-. \\");
    console.log("                 ;                           .-.    `-'  \\");
    console.log("     IT'S A     ;                            `-'          \\");
    console.log(
      "     TRAP!!!    ;                                          `."
    );
    console.log(
      "        \\      ;                                           :"
    );
    console.log(
      "         \\    ;                                            |"
    );
    console.log(
      "          \\  ;                                             ;"
    );
    console.log("           \\;                            ___              ;");
    console.log("           ;                        ,-;-','.`.__          |");
    console.log("       _..;                      ,-' ;`,'.`,'.--`.        |");
    console.log("      ///;           ,-'   `. ,-'   ;` ;`,','_.--=:      /");
    console.log("     |'':          ,'        :     ;` ;,;,,-'_.-._`.   ,'");
    console.log("     '  :         ;_.-.      `.    :' ;;;'.ee.    |  /");
    console.log("      .'    _..-'/8o. `.     :    :! ' ':8888)   || /");
    console.log("       ||`-''    \\88o :     :    :! :  :`\"\"'    ;;/");
    console.log("       ||         \"88o;     `.     `. `.      ;,'");
    console.log("       /)   ___    `.\"'/(--.._ `.    `.`.  `-..-' ;--.");
    console.log('       \\(.="""""==.. `\'-\'     `.|      `-`-..__.-\' `. `.');
    console.log('        |          `"==.__      )                    )  ;');
    console.log("        |   ||           `\"=== '                   .'  .'");
    console.log("        /,,||||  | |                           .'   .'");
    console.log("        | |||'|' |'|'           \\|             .'   _.' \\");
    console.log("        | |' |  |           || ||           .'    .'    \\");
    console.log("        ' |  ' |'  .   ``-- `| ||         .'    .'       \\");
    console.log(
      "          '  |  ' |  .    ``-.._ |  ;    .'    .'          `."
    );
    console.log(
      "       _.--,;`.       .  --  ...._,'   .'    .'              `.__"
    );
    console.log(
      "     ,'  ,';   `.     .   --..__..--'.'    .'                __/_\\"
    );
    console.log(
      "   ,'   ; ;     |    .   --..__.._.'     .'                ,'     `."
    );
    console.log(
      "  /    ; :     ;     .    -.. _.'     _.'                 /         `"
    );
    console.log(" /     :  `-._ |    .    _.--'     _.'                   |");
    console.log("/       `.    `--....--''       _.'                      |");
    console.log("          `._              _..-'                         |");
    console.log("             `-..____...-''                              |");
    console.log("                                                         |");
  };
}

export default AckbarService;
