import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import RecommendedContent from "../components/RecommendedContent";
import MoviePlayer from "../components/MoviePlayer";

function MoviePage() {
  const { isAuthenticated } = useAuth0();

  return (
    <div>
      <MoviePlayer />
      <RecommendedContent style={{ marginTop: "-3%" }} />
    </div>
  );
}

export default MoviePage;
