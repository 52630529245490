import React, { useState } from "react";
import Button from "react-gazelle/lib/components/Button";
import { API_ROOT } from "../constants/network";
import Modal from "../Modal";

function TrapdoorModal(props) {
  const [isEmailCaptured, setEmailCaptured] = useState(false);
  const [email, setEmail] = useState("");

  const itemId = () => {
    var itemId = window.location.pathname
      .split("/")
      .filter((x) => x != "app" && x != "")[0];
    return itemId === undefined ? "borrowed-future" : itemId;
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const signUp = (event) => {
    event.preventDefault();

    var response = fetch(`${API_ROOT}/media/${itemId()}/subscribe`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "content-type": "application/json",
      },
      body: `{\"email\": \"${email}\"}`,
    });

    setEmailCaptured(true);
  };

  const openSurvey = () => {
    window.open("https://survey.alchemer.com/s3/6557154/13c942ef6534").focus();
  };

  return (
    <Modal>
      <button className="closeButton" onClick={props.closeTrapdoor}>
        <svg
          class="gzl-Icon gzl-Icon--l"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill-rule="evenodd"
            d="M1.3 1.3a1 1 0 0 1 1.4 0l9.3 9.29 9.3-9.3a1 1 0 0 1 1.31-.08l.1.08a1 1 0 0 1 0 1.42L13.4 12l9.3 9.3a1 1 0 0 1 .08 1.31l-.08.1a1 1 0 0 1-1.42 0L12 13.4l-9.3 9.3a1 1 0 0 1-1.31.08l-.1-.08a1 1 0 0 1 0-1.42L10.6 12 1.3 2.7a1 1 0 0 1-.08-1.31z"
          />
        </svg>
      </button>
      <img
        className="TrapdoorModal-comingSoonIcon"
        src="https://cdn.ramseysolutions.net/media/b2c/ramsey-network/logos/show-coming-soon.svg"
      />
      <p className="TrapdoorModal-comingSoonLabel">
        This show is coming soon...
      </p>
      <p className="TrapdoorModal-Text">
        Do you like watching our shows? We are looking to add to our current
        lineup and we want to know what YOU want to see! Take our quick survey
        below to tell us your thoughts. (It really matters to us!)
      </p>
      <Button className="TrapdoorModal-surveyLink" onClick={openSurvey}>
        Take the survey
      </Button>
      <div className="TrapdoorModal-emailForm">
        <p className="TrapdoorModal-comingSoonLabel">Get Notified</p>
        <p className="TrapdoorModal-Text">
          Sign up to hear about this show's release and other Streaming TV
          releases from Ramsey Network
        </p>
        {!isEmailCaptured && (
          <form className="gzl-InlineForm centeredForm" onSubmit={signUp}>
            <label htmlFor="email" className="u-visuallyHidden">
              Email
            </label>
            <div className="gzl-InlineForm-control in-modeMergeable TrapdoorModal-emailInput">
              <div className="gzl-Input">
                <input
                  id="email"
                  placeholder="Enter your email"
                  onChange={handleChange}
                />
              </div>
              <Button>Sign Up</Button>
            </div>
          </form>
        )}
        {isEmailCaptured && (
          <p className="TrapdoorModal-Text">Thanks for signing up!</p>
        )}
      </div>
    </Modal>
  );
}

export default TrapdoorModal;
