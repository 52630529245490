import { useLocation, useHistory } from "react-router-dom";
import React from "react";
import ApopheniaLogger from "apophenia-javascript-logger";
import { LOGGING_API_KEY, LOGGING_APP_ID } from "../../constants/logging";
import Button from "react-gazelle/lib/components/Button";
import { useAuth0 } from "@auth0/auth0-react";

const PlayButton = ({ playVideo }) => {
  let location = useLocation();
  let history = useHistory();
  let { isAuthenticated, loginWithRedirect } = useAuth0();
  const logger = ApopheniaLogger.initialize({
    appId: LOGGING_APP_ID,
    apiKey: LOGGING_API_KEY,
  });

  const itemId = () => {
    var itemId = window.location.pathname
      .split("/")
      .filter((x) => x != "app" && x != "")[0];
    return itemId === undefined ? "borrowed-future" : itemId;
  };

  const handleClick = () => {
    if (playVideo != null) {
      playVideo();
    } else {
      if (isAuthenticated) {
        history.push(`video/${itemId()}`);
      } else {
        localStorage.setItem("redirectUrl", `video/${itemId()}`);
        loginWithRedirect();
      }
    }
  };

  return <Button onClick={handleClick}>Watch Now</Button>;
};

export default PlayButton;
