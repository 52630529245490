import { useLocation, useHistory } from "react-router-dom";
import ReactJWPlayer from "react-jw-player";
import React, { useState } from "react";
import { API_ROOT } from "../../constants/network";
import ApopheniaLogger from "apophenia-javascript-logger";
import { LOGGING_API_KEY, LOGGING_APP_ID } from "../../constants/logging";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

function VideoPage() {
  const { getIdTokenClaims, isAuthenticated, getAccessTokenSilently } =
    useAuth0();
  let location = useLocation();
  let history = useHistory();

  const logger = ApopheniaLogger.initialize({
    appId: LOGGING_APP_ID,
    apiKey: LOGGING_API_KEY,
  });

  const logProgress = async (position = null) => {
    if (showVideo) {
      position =
        position === null
          ? Math.floor(window.jwplayer().getPosition())
          : position;
      if (position > 30) {
        await getAccessTokenSilently().then(async () => {
          await getIdTokenClaims().then(async (token) => {
            const response = await fetch(
              `${API_ROOT}/media/${itemId()}/progress`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token.__raw}`,
                  "content-type": "application/json",
                },
                body: JSON.stringify(position),
              }
            );
          });
        });
      }
    }
  };

  const itemId = () => {
    var itemId = window.location.pathname
      .split("/")
      .filter((x) => x != "app" && x != "")[1];
    return itemId === undefined ? "borrowed-future" : itemId;
  };

  const [startTime, setStartTime] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [progressTimer, setProgressTimer] = useState(null);

  const getResource = async () => {
    await getAccessTokenSilently().then(async () => {
      let token;
      await getIdTokenClaims().then((data) => {
        token = data.__raw;
      });

      let url = `${API_ROOT}/${
        itemId() === "borrowed-future" ? "movies" : "media"
      }/${itemId()}/resource`;
      var response = await fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let videoUrl, userProgress;
      await response.json().then((data) => {
        // borrowed-future returns a signed url and has a vtt, no others do that.
        // videoUrl = itemId() === 'borrowed-future' ? data.signed_url : data.resource;
        videoUrl = data.resource;
        userProgress = data.progress;
      });
      setStartTime(userProgress);
      setVideoUrl(videoUrl);
      setShowVideo(true);
      if (progressTimer == null) {
        setProgressTimer(setInterval(logProgress, 15000));
      }
    });
  };

  getResource();

  const closePlayer = () => {
    window.jwplayer().stop();
    clearInterval(progressTimer);
    history.push(`/app/${itemId()}`);
  };

  const logFinish = () => {
    getAccessTokenSilently().then(async () => {
      getIdTokenClaims().then(async (token) => {
        const response = fetch(`${API_ROOT}/media/${itemId()}/progress`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token.__raw}`,
            "content-type": "application/json",
          },
          body: JSON.stringify(0),
        });
      });
    });
  };

  const onPlaybackPositionUpdate = (event) => {};

  const onAutoStart = (event) => {
    window.jwplayer().seek(startTime);

    logger.send("media.interaction.play", {
      media_id: itemId(),
      user_id: localStorage.getItem("axid"),
    });
  };

  const onPause = (event) => {
    logger.send("media.interaction.pause", {
      media_id: itemId(),
      user_id: localStorage.getItem("axid"),
    });
    logProgress(event.position);
  };

  const onPlay = (event) => {};

  const onResume = (event) => {
    logger.send("media.interaction.resume", {
      media_id: itemId(),
      user_id: localStorage.getItem("axid"),
    });
  };

  const onEnterFullScreen = () => {
    window
      .jwplayer()
      .addButton(
        "https://cdn.ramseysolutions.net/media/b2c/ramsey-network/logos/streamingtv/back-button.png",
        "",
        closePlayer,
        "jw-override-closeButton",
        "jw-override-closeButton"
      );
  };

  const onExitFullScreen = () => {
    //window.jwplayer().addButton("https://cdn.ramseysolutions.net/media/b2c/ramsey-network/logos/streamingtv/back-button.png", '', closePlayer, "jw-override-closeButton", "jw-override-closeButton");
  };

  const onError = (event) => {};

  const onMute = (event) => {};

  if (showVideo) {
    return (
      <div className="videoPlayerContainer fullScreen">
        <button className="closeVideoButton" onClick={closePlayer} />
        <ReactJWPlayer
          playerId="MyJWPlayer"
          playerScript="https://cdn.jwplayer.com/libraries/L1H1ocUG.js"
          file={videoUrl}
          aspectRatio="16:9"
          customProps={{ className: "fullScreen", responsive: true }}
          isAutoPlay={true}
          onTime={onPlaybackPositionUpdate}
          onAutoStart={onAutoStart}
          onPause={onPause}
          onPlay={onPlay}
          onEnterFullScreen={onEnterFullScreen}
          onExitFullScreen={closePlayer}
          onError={onError}
          onMute={onMute}
          onResume={onResume}
          onOneHundredPercent={logFinish}
        />
      </div>
    );
  } else {
    return <div className="fullScreen"></div>;
  }
}

export default withAuthenticationRequired(VideoPage, {
  onRedirecting: () => <div>Redirecting to login...</div>,
});
