import React from "react";

function Modal({ children }) {
  return (
    <div className="Modal">
      <div className="Modal-overlay">
        <div className="Modal-content">
          <div className="Modal-card">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
