import React from "react";
import RecommendedContent from "../../components/RecommendedContent";
import Trapdoor from "../../components/Trapdoor";

function TrapdoorPage() {
  return (
    <div className="fullScreen">
      <Trapdoor />
      <RecommendedContent />
    </div>
  );
}

export default TrapdoorPage;
