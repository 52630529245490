import { useAuth0 } from "@auth0/auth0-react";
import { PulseLoader } from "react-spinners";
import SiteHeader from "./components/SiteHeader";
import Watch from "./pages/Watch";
import "./css/global.scss";
import { Redirect } from "react-router";

function App() {
  const auth0 = useAuth0();

  const shouldRedirectToLogin = () => {
    var queryString = window.location.search.replace("?", "");
    var redirectFlag = queryString
      .split("&")
      .filter((x) => x.match(/redirectToLogin/g))[0];

    var cameFromSCA =
      queryString.match(/plan=/g) !== null &&
      queryString.match(/product=/g) !== null &&
      queryString.match(/subscription_id=/g) !== null;

    return (
      (redirectFlag != undefined && redirectFlag.split("=")[1] == "true") ||
      cameFromSCA
    );
  };

  const shouldRedirectToPayment = () => {
    var queryString = window.location.search.replace("?", "");
    var redirectFlag = queryString
      .split("&")
      .filter((x) => x.match(/redirectToPayment/g))[0];

    return redirectFlag != undefined && redirectFlag.split("=")[1] == "true";
  };

  const shouldRedirectToPage = () => {
    return localStorage.getItem("redirectUrl") != undefined;
  };

  if (auth0.isLoading) {
    return (
      <div style={{ backgroundColor: "#000000", height: "100%" }}>
        <div
          style={{
            margin: "auto",
            display: "table",
            backgroundColor: "#000000",
            paddingTop: "15em",
          }}
        >
          <PulseLoader color="#FFFFFF" size="10px" margin="3px"></PulseLoader>
        </div>
      </div>
    );
  } else {
    if (!auth0.isAuthenticated && shouldRedirectToLogin()) {
      if (shouldRedirectToPayment()) {
        localStorage.setItem("redirectToPayments", true);
      }
      auth0.loginWithRedirect();
    } else if (auth0.isAuthenticated && shouldRedirectToPage()) {
      var redirectUrl = localStorage.getItem("redirectUrl");
      localStorage.clear("redirectUrl");
      return <Redirect to={redirectUrl} />;
    } else {
      return (
        <div className="App">
          <SiteHeader transparent />
          <Watch />
        </div>
      );
    }
  }
}

export default App;
