import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import GzlHeading, { TAGS } from "react-gazelle/lib/components/Heading";
import ShowCard from "../ShowCard";
import Carousel from "../Carousel";
import Flickity from "react-flickity-component";
import "../../css/flickity.css";
import { API_ROOT } from "../../constants/network";

const flickityOptions = {
  initialIndex: 2,
  cellAlign: "center",
  wrapAround: true,
  pageDots: false,
  groupCells: 4,
  prevNextButtons: true,
  contain: true,
};

const RecommendedContent = (props) => {
  const location = useLocation();
  const [playlist, setPlaylist] = useState([{}]);
  let flkty;

  const itemId = () => {
    var itemId = window.location.pathname
      .split("/")
      .filter((x) => x != "app" && x != "")[0];
    return itemId === undefined ? "borrowed-future" : itemId;
  };

  const fetchPlaylist = async () => {
    const response = await fetch(`${API_ROOT}/media/related/${itemId()}`, {
      method: "GET",
      cache: "no-cache",
    });
    const currentMediaId = location.pathname.split("/").pop();

    await response.json().then((data) => {
      const content = data
        .filter((show) => show.media_id !== currentMediaId)
        .map((show) => ({
          mediaId: show.media_id,
          description: show.description,
          backgroundImgSrc: show.background_img_src,
          hoverImgSrc: show.hover_background_img_src,
          logoImgSrc: show.logo_img_src,
          subdescription: show.subdescription,
          position: -1,
        }));

      setPlaylist(content);
      if (flkty != undefined) {
        flkty.on("ready", () => {
          flkty.selectCell(0);
        });
      }
    });
  };

  useEffect(() => {
    if (flkty != undefined) {
      if (matchMedia("screen and (min-width: 500px)").matches) {
        flkty.options.prevNextButtons = true;
        flkty.options.groupCells = 4;
      } else {
        flkty.options.prevNextButtons = false;
        flkty.options.groupCells = 1;
      }
    }
    fetchPlaylist();
  }, [location]);

  return (
    <div
      className={classNames("RecommendedContent in-modeDark", {
        "RecommendedContent--has-progress": props.hasProgress,
      })}
      style={props.style}
    >
      <GzlHeading className="RecommendedContent-title" tag={TAGS.H2}>
        Check Out More Ramsey Network Streaming TV Shows
      </GzlHeading>
      <Flickity
        flickityRef={(c) => (flkty = c)}
        className="carousel" // default ''
        options={flickityOptions} // takes flickity options {}
      >
        {playlist.map((show, i) => {
          if (show.mediaId != undefined) {
            show.position = i;
            return <ShowCard key={i} {...show}></ShowCard>;
          }
        })}
      </Flickity>
    </div>
  );
};

export default RecommendedContent;
