import React from "react";
import ReactJWPlayer from "react-jw-player";
import { withAuth0 } from "@auth0/auth0-react";
import VideoMetadata from "../VideoMetadata";
import { API_ROOT } from "../../constants/network";
import ApopheniaLogger from "apophenia-javascript-logger";
import { LOGGING_API_KEY, LOGGING_APP_ID } from "../../constants/logging";

class VideoPlayer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      showVideo: false,
      showLogin: false,
      showCreateAccount: false,
      showRedeemVoucher: false,
      itemMetadata: {
        titleImageSrc:
          "https://cdn.ramseysolutions.net/media/b2c/ramsey-network/images/borrowed-future-logo.png",
        description:
          "Borrowed Future uncovers the dark side of the student loan industry and exposes how the system is built to work against you. Streaming now.",
        price: "2.99",
        backgroundImageSrc:
          "https://cdn.ramseysolutions.net/media/b2c/ramsey-network/images/borrowed-future-app-background.png",
        duration: "1H 28M",
        rating: "NR",
        releaseYear: "2021",
        title: "Borrowed Future",
        trailerUrl:
          "https://streaming.ramseysolutions.net/video/borrowed-future-documentary/Borrowed_Future_Trailer_FINAL.mp4/hls/Borrowed_Future_Trailer_FINAL.m3u8",
      },
      showTrailer: false,
      hasLoggedComplete: false,
      isDataLoaded: false,
      progressTimer: null,
      isPlaying: false,
      progress: 0,
    };

    this.getMetadata(props.itemId);
  }

  componentDidMount() {
    const intervalId = setInterval(this.logProgress, 15000);

    this.setState({
      progressTimer: intervalId,
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.progressTimer);
  }

  getMetadata = async (itemId) => {
    if (!this.state.isDataLoaded) {
      var response = await fetch(`${API_ROOT}/movies/${itemId}`, {
        method: "GET",
        cache: "no-cache",
      });

      let metadata = {};
      await response.json().then((data) => {
        let durationHours = Math.floor(data.duration / 60);
        let durationMinutes = data.duration % 60;

        metadata.titleImageSrc = data.title_image_source;
        metadata.description = data.description;
        metadata.price = data.price;
        metadata.backgroundImageSrc = data.background_image_source;
        metadata.duration =
          durationHours > 0
            ? `${durationHours}H ${durationMinutes}M`
            : `${durationMinutes}M`;
        metadata.rating = data.rating;
        metadata.releaseYear = data.release_year;
        metadata.title = data.title;
        metadata.trailerUrl = data.trailer_url;

        this.setState({
          isDataLoaded: true,
          itemMetadata: metadata,
        });
      });
    }
  };

  setSignedUrl = async () => {
    //fetch from API
    await this.props.auth0.getAccessTokenSilently().then(async () => {
      await this.props.auth0.getIdTokenClaims().then(async (data) => {
        const response = await fetch(
          `${API_ROOT}/movies/borrowed-future/resource`,
          {
            headers: {
              Authorization: `Bearer ${data.__raw}`,
            },
          }
        );

        response.json().then((data) => {
          var playlist = [
            {
              file: data.signed_url,
              tracks: [
                {
                  kind: "captions",
                  file: data.vtt_url,
                  label: "English",
                },
              ],
            },
          ];

          this.setState({
            videoPlaylist: playlist,
            progress: data.progress,
          });
        });
      });
    });
  };

  logProgress = () => {
    if (this.state.isPlaying && this.state.showVideo) {
      var progressInSeconds = Math.floor(window.jwplayer().getPosition());
      if (progressInSeconds > 30) {
        this.props.auth0.getAccessTokenSilently().then(() => {
          this.props.auth0.getIdTokenClaims().then((token) => {
            fetch(`${API_ROOT}/media/borrowed-future/progress`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token.__raw}`,
                "content-type": "application/json",
              },
              body: JSON.stringify(progressInSeconds),
            });
          });
        });
      }
    }
  };

  logFinish = () => {
    this.props.auth0.getAccessTokenSilently().then(() => {
      this.props.auth0.getIdTokenClaims().then((token) => {
        fetch(`${API_ROOT}/media/borrowed-future/progress`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token.__raw}`,
            "content-type": "application/json",
          },
          body: JSON.stringify(0),
        });
      });
    });

    this.setState({
      isPlaying: false,
    });
  };

  playVideo = async () => {
    await this.setSignedUrl();

    this.setState({
      showVideo: true,
      isPlaying: true,
    });

    localStorage.setItem("hasBorrowedFutureStarted", true);
    this.props.onWatch(true);
  };

  render() {
    return (
      <div
        className="VideoOverview"
        style={{
          backgroundImage:
            "url(" + this.state.itemMetadata.backgroundImageSrc + ")",
        }}
      >
        <VideoMetadata
          {...this.state.itemMetadata}
          watchTrailer={this.watchTrailer}
          watchFree={false}
          onPlayClick={this.playVideo}
          logoMarginTop="4em"
        />

        {this.state.showVideo && (
          <div className="videoPlayerContainer fullScreen">
            <button className="closeVideoButton" onClick={this.closePlayer} />
            <ReactJWPlayer
              playerId="MyJWPlayer"
              playerScript="https://cdn.jwplayer.com/libraries/L1H1ocUG.js"
              playlist={this.state.videoPlaylist}
              aspectRatio="16:9"
              customProps={{ className: "fullScreen" }}
              isAutoPlay={true}
              onTime={this.onPlaybackPositionUpdate}
              onAutoStart={this.onVideoStart}
              onPause={this.onPause}
              onPlay={this.onPlay}
              onEnterFullScreen={this.onEnterFullScreen}
              onExitFullScreen={this.onExitFullScreen}
              onError={this.onError}
              onMute={this.onMute}
              onResume={this.onResume}
              onOneHundredPercent={this.logFinish}
            />
          </div>
        )}
        {this.state.showTrailer && (
          <div className="videoPlayerContainer fullScreen">
            <button className="closeVideoButton" onClick={this.closePlayer} />
            <ReactJWPlayer
              playerId="MyJWPlayer"
              playerScript="https://cdn.jwplayer.com/libraries/L1H1ocUG.js"
              file={this.state.itemMetadata.trailerUrl}
              aspectRatio="16:9"
              customProps={{ className: "fullScreen" }}
              isAutoPlay={true}
              onTime={this.onPlaybackPositionUpdate}
              onExitFullScreen={this.onExitFullScreen}
              onAutoStart={this.onTrailerStart}
            />
          </div>
        )}
      </div>
    );
  }

  watchTrailer = () => {
    this.setState({
      showTrailer: true,
    });
  };

  closePlayer = () => {
    document.body.style["overflow-y"] = "auto";
    window.jwplayer().stop();
    this.setState({
      showTrailer: false,
      showVideo: false,
      isPlaying: false,
    });
  };

  setVideoPlayStatus = (isPlaying) => {
    this.setState({
      isPlaying: isPlaying,
    });
  };

  onPlaybackPositionUpdate = (event) => {
    const { itemId } = this.props;

    let count = this.state.updateCount;
    if (count === 100) {
      this.setState({
        updateCount: 0,
        startTime: event.position,
      });
      localStorage.setItem(`${itemId}_progress`, event.position);
      // log updated timestamp to database
    } else {
      count++;
      this.setState({
        updateCount: count,
      });
    }

    if (
      event.position >= 5040 &&
      localStorage.getItem(`${itemId}_isContentCompleted`) != "true"
    ) {
      localStorage.setItem(`${itemId}_isContentCompleted`, true);
      window.gtmDataLayer = window.gtmDataLayer || [];
      window.gtmDataLayer.push({
        event: "contentComplete",
        contentCompleteLabel: "borrowed future documentary",
      });
    }
  };

  onVideoStart = (event) => {
    window
      .jwplayer()
      .addButton(
        "https://cdn.ramseysolutions.net/media/b2c/ramsey-network/logos/streamingtv/back-button.png",
        "",
        this.closePlayer,
        "jw-override-closeButton",
        "jw-override-closeButton"
      );
    window.jwplayer().setFullscreen(true);
    window.jwplayer().seek(this.state.progress);

    ApopheniaLogger.initialize({
      appId: LOGGING_APP_ID,
      apiKey: LOGGING_API_KEY,
    }).send("media.interaction.play", {
      media_id: "borrowed-future",
      user_id: localStorage.getItem("axid"),
    });

    this.setVideoPlayStatus(window.jwplayer().getState() === "playing");
  };

  onTrailerStart = (event) => {
    window.jwplayer().setFullscreen(true);
  };

  onEnterFullScreen = (event) => {
    // No event standards entry exists yet
    // Logger.send('event.name', {
    //     media_id: '',
    //     user_axid: ''
    // });
  };

  onExitFullScreen = (event) => {
    this.closePlayer();
    // No event standards entry exists yet
    // Logger.send('event.name', {
    //     media_id: '',
    //     user_axid: ''
    // });
  };

  onError = (event) => {
    // No event standards entry exists yet
    // Logger.send('event.name', {
    //     media_id: '',
    //     user_axid: ''
    // });
  };

  onMute = (event) => {
    // No event standards entry exists yet
    // Logger.send('event.name', {
    //     media_id: '',
    //     user_axid: ''
    // });
  };

  onPause = (event) => {
    ApopheniaLogger.initialize({
      appId: LOGGING_APP_ID,
      apiKey: LOGGING_API_KEY,
    }).send("media.interaction.pause", {
      media_id: "borrowed-future",
      user_id: localStorage.getItem("axid"),
    });

    this.setVideoPlayStatus(window.jwplayer().getState() === "playing");
  };

  onPlay = (event) => {
    // Not sure this is needed since onVideoStart also fires
    // Logger.send('media.interaction.play', {
    //     media_id: '',
    //     user_axid: ''
    // });

    this.setVideoPlayStatus(window.jwplayer().getState() === "playing");
  };

  onResume = (event) => {
    ApopheniaLogger.initialize({
      appId: LOGGING_APP_ID,
      apiKey: LOGGING_API_KEY,
    }).send("media.interaction.resume", {
      media_id: "borrowed-future",
      user_id: localStorage.getItem("axid"),
    });

    this.setVideoPlayStatus(window.jwplayer().getState() === "playing");
  };
}

export default withAuth0(VideoPlayer);
