import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_REDIRECT_URL,
} from "./constants/auth";
import "core-js/stable";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import MoviePage from "./pages/MoviePage.jsx";
import ScrollToTop from "./utilities/ScrollToTop";
import SiteHeader from "./components/SiteHeader";
import TrapdoorPage from "./pages/TrapdoorPage/TrapdoorPage";
import VideoPage from "./pages/VideoPage/VideoPage";
// import reportWebVitals from './reportWebVitals';

export const history = createBrowserHistory();

const onRedirectCallback = (appState) => {
  history.replace(appState?.returnTo || window.location.pathname);
};

const backListener = history.listen((location) => {
  if (
    history.location.pathname.match(/\/app\//g) === null &&
    history.action === "POP"
  ) {
    //alert("don't be a dummy, stay with mummy");
  }
});

ReactDOM.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    redirectUri={AUTH0_REDIRECT_URL}
    useRefreshTokens={true}
    onRedirectCallback={onRedirectCallback}
  >
    <Router history={history}>
      <Switch>
        <Route path="/app/" exact>
          <ScrollToTop />
          <App />
        </Route>
        <Route path={`/app/video/:itemId`} exact>
          <VideoPage></VideoPage>
        </Route>
        <Route path="/app/borrowed-future">
          <ScrollToTop />
          <App />
        </Route>
        <Route path="/app/headlines">
          <ScrollToTop />
          <div>
            <SiteHeader />
            <TrapdoorPage />
          </div>
        </Route>
        <Route path="/app/life-renovations">
          <ScrollToTop />
          <div>
            <SiteHeader />
            <TrapdoorPage />
          </div>
        </Route>
        <Route path="/app/*">
          <ScrollToTop />
          <div>
            <SiteHeader />
            <MoviePage />
          </div>
        </Route>
      </Switch>
    </Router>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
