import React from "react";
import Button, { VARIANTS } from "react-gazelle/lib/components/Button";
import PlayButton from "../PlayButton";
import SmartWatchButton from "../SmartWatchButton";

const VideoMetadata = ({
  description,
  duration,
  price,
  rating,
  releaseYear,
  title,
  titleImageSrc,
  watchTrailer,
  playVideo,
  watchFree = true,
  moreEpisodes = false,
  onPlayClick = () => {},
  logoMarginTop = "0em",
}) => {
  const itemId = () => {
    var itemId = window.location.pathname
      .split("/")
      .filter((x) => x != "app" && x != "")[0];
    return itemId === undefined ? "borrowed-future" : itemId;
  };

  const goToMarketingPage = () => {
    window.open(
      `https://www.ramseysolutions.com/shows/${itemId()}#recent-episodes`
    );
  };

  return (
    <div className="VideoMetadata in-modeDark">
      <div className="VideoMetadata-body">
        <img
          src={titleImageSrc}
          className="VideoMetadata-titleImage"
          alt={title}
          style={{ marginTop: logoMarginTop }}
        />
        <p className="VideoMetadata-mediaInfo">
          <span>{duration}</span>
          <span>{releaseYear}</span>
          <span className="VideoMetadata-mediaRating">{rating}</span>
        </p>
        <p className="VideoMetadata-itemDescription">{description}</p>

        <div className="VideoMetadata-actions">
          {watchFree ? (
            <PlayButton playVideo={playVideo} />
          ) : (
            <div className="VideoMetadata-actions">
              <Button
                className="VideoMetadata-watch"
                variant={VARIANTS.GHOST}
                onClick={watchTrailer}
              >
                Watch Trailer
              </Button>
              <SmartWatchButton
                price={price}
                onClick={onPlayClick}
              ></SmartWatchButton>
            </div>
          )}
          {moreEpisodes && (
            <Button
              className="VideoMetadata-moreEpisodes"
              variant={VARIANTS.GHOST}
              onClick={goToMarketingPage}
            >
              More Episodes
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoMetadata;
