import { useLocation, useHistory } from "react-router-dom";
import ReactJWPlayer from "react-jw-player";
import React, { useEffect, useState, useRef } from "react";
import { API_ROOT } from "../../constants/network";
import ApopheniaLogger from "apophenia-javascript-logger";
import { LOGGING_API_KEY, LOGGING_APP_ID } from "../../constants/logging";
import { useAuth0 } from "@auth0/auth0-react";
import { AUTH0_REDIRECT_URL } from "../../constants/auth";
import VideoMetadata from "../VideoMetadata";

function MoviePlayer() {
  let location = useLocation();
  let history = useHistory();

  const {
    getIdTokenClaims,
    isAuthenticated,
    getAccessTokenSilently,
    buildAuthorizeUrl,
  } = useAuth0();

  const itemId = () => {
    var itemId = window.location.pathname
      .split("/")
      .filter((x) => x != "app" && x != "")[0];
    return itemId === undefined ? "borrowed-future" : itemId;
  };

  const [itemMetadata, setItemMetadata] = useState({});
  const [showVideo, setShowVideo] = useState(false);
  const [hasLoggedComplete, setHasLoggedComplete] = useState(false);
  const [updateCount, setUpdateCount] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");

  const logger = ApopheniaLogger.initialize({
    appId: LOGGING_APP_ID,
    apiKey: LOGGING_API_KEY,
  });

  const getMetadata = async () => {
    var response = await fetch(`${API_ROOT}/movies/${itemId()}`, {
      method: "GET",
      cache: "no-cache",
    });

    let metadata = {};
    if (response.status == 200) {
      await response.json().then((data) => {
        let durationHours = Math.floor(data.duration / 60);
        let durationMinutes = data.duration % 60;

        metadata.titleImageSrc = data.title_image_source;
        metadata.description = data.description;
        metadata.price = data.price;
        metadata.backgroundImageSrc = data.background_image_source;
        metadata.duration =
          durationHours > 0
            ? `${durationHours}H ${durationMinutes}M`
            : `${durationMinutes}M`;
        metadata.rating = data.rating;
        metadata.releaseYear = data.release_year;
        metadata.title = data.title;
        metadata.trailerUrl = data.trailer_url;

        setItemMetadata(metadata);
      });
    } else {
      history.push("/app");
    }
  };

  useEffect(() => {
    getMetadata();
  }, [location]);

  useEffect(() => {
    const interval = setInterval(logProgress, 15000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const playVideo = async () => {
    if (
      !isAuthenticated &&
      window.location.hostname.match(/localhost/g) == null
    ) {
      await buildAuthorizeUrl({
        redirect_uri: `${AUTH0_REDIRECT_URL}/${itemId()}`,
      }).then((data) => {
        window.location = data;
      });
    } else {
      await getAccessTokenSilently().then(async () => {
        let token;
        await getIdTokenClaims().then((data) => {
          token = data.__raw;
        });

        var response = await fetch(`${API_ROOT}/media/${itemId()}/resource`, {
          method: "GET",
          cache: "no-cache",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        let videoUrl, userProgress;
        if (response.status == 200) {
          await response.json().then((data) => {
            videoUrl = data.resource;
            userProgress = data.progress;
          });

          setStartTime(userProgress);
          setVideoUrl(videoUrl);
          setShowVideo(true);
        } else {
          history.push("/app");
        }
      });
    }
  };

  const closePlayer = () => {
    document.body.style["overflow-y"] = "auto";
    window.jwplayer().stop();
    setShowVideo(false);
  };

  const logProgress = async () => {
    if (
      document.getElementsByClassName("videoPlayerContainer").length > 0 &&
      window.jwplayer().getState() == "playing"
    ) {
      var progressInSeconds = Math.floor(window.jwplayer().getPosition());
      if (progressInSeconds > 30) {
        await getAccessTokenSilently().then(async () => {
          await getIdTokenClaims().then(async (token) => {
            const response = await fetch(
              `${API_ROOT}/media/${itemId()}/progress`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token.__raw}`,
                  "content-type": "application/json",
                },
                body: JSON.stringify(progressInSeconds),
              }
            );
          });
        });
      }
    }
  };

  const logFinish = () => {
    getAccessTokenSilently().then(async () => {
      getIdTokenClaims().then(async (token) => {
        const response = fetch(`${API_ROOT}/media/${itemId()}/progress`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token.__raw}`,
            "content-type": "application/json",
          },
          body: JSON.stringify(0),
        });
      });
    });
  };

  const onPlaybackPositionUpdate = (event) => {
    var count = updateCount;
    if (count === 10) {
      setUpdateCount(0);
      setStartTime(event.position);
    } else {
      count++;
      setUpdateCount(count);
    }
  };

  const onVideoStart = (event) => {
    //TODO: Add a back button
    window
      .jwplayer()
      .addButton(
        "https://cdn.ramseysolutions.net/media/b2c/ramsey-network/logos/streamingtv/back-button.png",
        "",
        closePlayer,
        "jw-override-closeButton",
        "jw-override-closeButton"
      );
    window.jwplayer().setFullscreen(true);
    window.jwplayer().seek(startTime);

    logger.send("media.interaction.play", {
      media_id: itemId(),
      user_id: localStorage.getItem("axid"),
    });
  };

  const onPause = (event) => {
    logger.send("media.interaction.pause", {
      media_id: itemId(),
      user_id: localStorage.getItem("axid"),
    });
    logProgress(event.position);
  };

  const onResume = (event) => {
    logger.send("media.interaction.resume", {
      media_id: itemId(),
      user_id: localStorage.getItem("axid"),
    });
  };

  return (
    <div
      className="MoviePlayer"
      style={{
        backgroundImage: "url(" + itemMetadata.backgroundImageSrc + ")",
      }}
    >
      <VideoMetadata
        {...itemMetadata}
        moreEpisodes={
          itemId() !== "never-give-up" && itemId() !== "live-like-no-one-else"
        }
      />

      {showVideo && (
        <div className="videoPlayerContainer fullScreen">
          <button className="closeVideoButton" onClick={closePlayer} />
          <ReactJWPlayer
            playerId="MyJWPlayer"
            playerScript="https://cdn.jwplayer.com/libraries/L1H1ocUG.js"
            file={videoUrl}
            aspectRatio="16:9"
            customProps={{ className: "fullScreen", responsive: true }}
            isAutoPlay={true}
            onTime={onPlaybackPositionUpdate}
            onAutoStart={onVideoStart}
            onPause={onPause}
            // onPlay={onPlay}
            // onEnterFullScreen={onEnterFullScreen}
            onExitFullScreen={closePlayer}
            // onError={onError}
            // onMute={onMute}
            onResume={onResume}
            onOneHundredPercent={logFinish}
          />
        </div>
      )}
    </div>
  );
}

export default MoviePlayer;
