import React from "react";
import ReactJWPlayer from "react-jw-player";
import { withAuth0 } from "@auth0/auth0-react";

import VideoMetadata from "../VideoMetadata";

import { API_ROOT } from "../../constants/network";

class VideoOverview extends React.Component {
  constructor(props) {
    super();
    this.state = {
      showLogin: false,
      showCreateAccount: false,
      showRedeemVoucher: false,
      itemMetadata: {
        titleImageSrc:
          "https://cdn.ramseysolutions.net/media/b2c/ramsey-network/images/borrowed-future-logo.png",
        description:
          "Borrowed Future uncovers the dark side of the student loan industry and exposes how the system is built to work against you. Streaming now.",
        price: "2.99",
        backgroundImageSrc:
          "https://cdn.ramseysolutions.net/media/b2c/ramsey-network/images/borrowed-future-app-background.png",
        duration: "1H 28M",
        rating: "NR",
        releaseYear: "2021",
        title: "Borrowed Future",
        trailerUrl:
          "https://streaming.ramseysolutions.net/video/borrowed-future-documentary/Borrowed_Future_Trailer_FINAL.mp4/hls/Borrowed_Future_Trailer_FINAL.m3u8",
      },
      showTrailer: false,
      hasLoggedComplete: false,
      isDataLoaded: false,
    };

    this.getMetadata(props.itemId);
  }

  getMetadata = async (itemId) => {
    if (!this.state.isDataLoaded) {
      var response = await fetch(`${API_ROOT}/movies/${itemId}`, {
        method: "GET",
        cache: "no-cache",
      });

      let metadata = {};
      if (response.status == 200) {
        await response.json().then((data) => {
          let durationHours = Math.floor(data.duration / 60);
          let durationMinutes = data.duration % 60;

          metadata.titleImageSrc = data.title_image_source;
          metadata.description = data.description;
          metadata.price = data.price;
          metadata.backgroundImageSrc = data.background_image_source;
          metadata.duration =
            durationHours > 0
              ? `${durationHours}H ${durationMinutes}M`
              : `${durationMinutes}M`;
          metadata.rating = data.rating;
          metadata.releaseYear = data.release_year;
          metadata.title = data.title;
          metadata.trailerUrl = data.trailer_url;

          this.setState({
            isDataLoaded: true,
            itemMetadata: metadata,
          });
        });
      } else {
        this.setState({
          isDataLoaded: true,
        });
      }
    }
  };

  render() {
    return (
      <div
        className="VideoOverview"
        style={{
          backgroundImage:
            "url(" + this.state.itemMetadata.backgroundImageSrc + ")",
        }}
      >
        <VideoMetadata
          {...this.state.itemMetadata}
          watchTrailer={this.watchTrailer}
          watchFree={false}
          logoMarginTop="4em"
        />

        {this.state.showTrailer && (
          <div className="videoPlayerContainer fullScreen">
            <button className="closeVideoButton" onClick={this.closePlayer} />
            <ReactJWPlayer
              playerId="MyJWPlayer"
              playerScript="https://cdn.jwplayer.com/libraries/L1H1ocUG.js"
              file={this.state.itemMetadata.trailerUrl}
              aspectRatio="16:9"
              customProps={{ className: "fullScreen" }}
              isAutoPlay={true}
              onTime={this.onPlaybackPositionUpdate}
            />
          </div>
        )}
      </div>
    );
  }

  watchTrailer = () => {
    this.setState({
      showTrailer: true,
    });
  };

  closePlayer = () => {
    this.setState({
      showTrailer: false,
    });
  };

  onPlaybackPositionUpdate = (event) => {
    if (event.position >= 140 && !this.state.hasLoggedComplete) {
      this.setState({
        hasLoggedComplete: true,
      });
      console.log("Playback is nearly complete");
    }
  };
}

export default withAuth0(VideoOverview);
