import { useState, useEffect } from "react";
import Button from "react-gazelle/lib/components/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { SCA_URL, API_ROOT } from "../constants/network";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import PlayButton from "./PlayButton";

function SmartWatchButton(props) {
  const BUTTON_LABELS = {
    RENT: `Rent $${props.price}`,
    WATCH: "Watch Now",
    WATCH_AGAIN: "Watch Again",
    WATCH_RPLUS: "Watch Now",
    WATCH_AGAIN_RPLUS: "Watch Again",
  };
  const {
    isAuthenticated,
    loginWithRedirect,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0();
  let location = useLocation();

  const [buttonState, setButtonState] = useState("RENT");

  const processRental = () => {
    if (!isAuthenticated) {
      localStorage.setItem("redirectToPayments", true);
      loginWithRedirect();
    } else {
      localStorage.removeItem("redirectToPayments");
      window.location = SCA_URL;
    }
  };

  const processWatchFree = () => {
    //TODO: Flag autoplay after login
    loginWithRedirect();
  };

  const fetchStatus = async () => {
    if (isAuthenticated) {
      let token;
      await getAccessTokenSilently().then(async () => {
        await getIdTokenClaims().then(async (data) => {
          var response = await fetch(`${API_ROOT}/authorize/borrowed-future`, {
            method: "POST",
            cache: "no-cache",
            headers: {
              Authorization: `Bearer ${data.__raw}`,
            },
          });
          await response.json().then((data) => {
            setButtonState(data);
          });
        });
      });
    } else if (props.price > 0) {
      setButtonState("RENT");
    }
  };

  useEffect(() => {
    fetchStatus();
  }, [location]);

  switch (buttonState) {
    case "RENT":
      return (
        <Button onClick={processRental}>{BUTTON_LABELS[buttonState]}</Button>
      );
    case "WATCH":
    case "WATCH_AGAIN":
      return <PlayButton playVideo={props.onClick} />;
    case "WATCH_RPLUS":
      return (
        <Button onClick={props.onClick}>{BUTTON_LABELS[buttonState]}</Button>
      );
    case "WATCH_AGAIN_RPLUS":
      return (
        <Button onClick={props.onClick}>{BUTTON_LABELS[buttonState]}</Button>
      );
    case "RESUME":
      break;
    default:
      break;
  }
}

export default SmartWatchButton;
