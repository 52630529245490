import Button from "react-gazelle/lib/components/Button";
import { useAuth0, withAuth0 } from "@auth0/auth0-react";
import { API_ROOT } from "../../constants/network.js";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

const NextSteps = ({ hasProgress }) => (
  <div>
    {hasProgress ? (
      <div className="NextSteps">
        <img
          className="NextSteps-banner"
          src="https://cdn.ramseysolutions.net/media/b2c/ramsey-network/logos/whats-next.svg"
        />

        <div className="NextSteps-actions">
          <div className="NextSteps-actionsItem">
            <div className="NextSteps-imageContainer">
              <img
                className="NextSteps-image NextSteps-image--left"
                src="https://cdn.ramseysolutions.net/media/b2c/ramsey-network/images/Borrowed_Future_Next_Steps/going-to-college.png"
              />
            </div>

            <div className="NextSteps-actionsItemTitle">
              <h5 className="NextSteps-actionsItemTitleText">
                Explore Options to
              </h5>
              <h5 className="NextSteps-actionsItemTitleText">
                Pay for College
              </h5>
            </div>

            <p className="NextSteps-actionsItemDescription">
              Target your dream job, choose your dream school,
              <br /> find free money, and{" "}
              <span className="NextSteps-actionsItemDescription--semi-bold">
                graduate debt-free!
              </span>
            </p>

            <div className="NextSteps-actionsItemLink">
              <Button href="https://www.ramseysolutions.com/shows/borrowed-future-documentary/students/">
                Go Debt Free
              </Button>
            </div>
          </div>

          <div className="NextSteps-actionsItem">
            <div className="NextSteps-imageContainer">
              <img
                className="NextSteps-image NextSteps-image--right"
                src="https://cdn.ramseysolutions.net/media/b2c/ramsey-network/images/Borrowed_Future_Next_Steps/paying-off-student-loans.png"
              />
            </div>

            <div className="NextSteps-actionsItemTitle">
              <h5 className="NextSteps-actionsItemTitleText">
                3 Steps to Pay Off
              </h5>
              <h5 className="NextSteps-actionsItemTitleText">Student Loans</h5>
            </div>

            <p className="NextSteps-actionsItemDescription">
              Master the debt snowball, take control of your
              <br /> money, and{" "}
              <span className="NextSteps-actionsItemDescription--semi-bold">
                get a plan for the future!
              </span>
            </p>

            <div className="NextSteps-actionsItemLink">
              <Button href="https://www.ramseysolutions.com/shows/borrowed-future-documentary/student-loan-debt">
                You Can Do It
              </Button>
            </div>
          </div>
        </div>
      </div>
    ) : null}
  </div>
);

export default withAuth0(NextSteps);
