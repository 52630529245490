class EnvironmentService {
  PREFIXES = {
    dev: "test.",
    test: "test.",
    qa: "qa.",
    prod: "",
  };

  AUTH0_CLIENT_IDS = {
    dev: process.env.REACT_APP_AUTH0_CLIENT_ID_TEST,
    test: process.env.REACT_APP_AUTH0_CLIENT_ID_TEST,
    qa: process.env.REACT_APP_AUTH0_CLIENT_ID_QA,
    prod: process.env.REACT_APP_AUTH0_CLIENT_ID_PROD,
  };

  LOGGING_API_KEYS = {
    dev: process.env.REACT_APP_LOGGING_API_KEY_TEST,
    test: process.env.REACT_APP_LOGGING_API_KEY_TEST,
    qa: process.env.REACT_APP_LOGGING_API_KEY_QA,
    prod: process.env.REACT_APP_LOGGING_API_KEY_PROD,
  };

  static get = () => new EnvironmentService().environment();
  static prefix = () => new EnvironmentService().prefix();
  static auth0_client_id = () => new EnvironmentService().auth0_client_id();
  static logging_api_key = () => new EnvironmentService().logging_api_key();

  environment = () => {
    if (window.location.hostname === "localhost") return "dev";

    switch (window.location.host.split(".")[1]) {
      case "test":
        return "test";
      case "qa":
        return "qa";
      case "ramseysolutions":
        return "prod";
      default:
        return "err";
    }
  };

  prefix = () => this.PREFIXES[this.environment()];

  auth0_client_id = () => this.AUTH0_CLIENT_IDS[this.environment()];

  logging_api_key = () => this.LOGGING_API_KEYS[this.environment()];
}

export default EnvironmentService;
