import EnvironmentService from "../utilities/EnvironmentService";

export const AUTH0_DOMAIN =
  window.location.hostname === "localhost"
    ? "id.test.ramseysolutions.com"
    : `id.${EnvironmentService.prefix()}ramseysolutions.com`;

export const AUTH0_CLIENT_ID = EnvironmentService.auth0_client_id();

export const AUTH0_REDIRECT_URL =
  window.location.hostname === "localhost"
    ? "http://localhost:3000/app"
    : `https://watch.${EnvironmentService.prefix()}ramseysolutions.com/app/`;
