import React from "react";
import { Link } from "react-router-dom";

const ShowCard = ({
  title,
  mediaId,
  description,
  backgroundImgSrc,
  hoverImgSrc,
  logoImgSrc,
  position,
  subdescription,
}) => (
  <Link
    className="ShowCard"
    to={`/app/${mediaId}`}
    data-analytics-link-label={`${position}_${mediaId}`}
  >
    <img className="ShowCard-img" src={backgroundImgSrc} alt={title} />
    <img
      className="ShowCard-img ShowCard-img--hover"
      src={hoverImgSrc}
      alt={title}
    />
    <div className="ShowCard-description">
      <img
        className="ShowCard-img ShowCard-img--title"
        src={logoImgSrc}
        alt={title}
      />
      <h3 className="ShowCard-descriptionSeasons">{subdescription}</h3>
      <p className="ShowCard-descriptionText">{description}</p>
    </div>
  </Link>
);

export default ShowCard;
