import { useLocation, useHistory } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { API_ROOT } from "../../constants/network";
import Button from "react-gazelle/lib/components/Button";
import ApopheniaLogger from "apophenia-javascript-logger";
import { LOGGING_API_KEY, LOGGING_APP_ID } from "../../constants/logging";
import AckbarService from "../../utilities/AckbarService";
import TrapdoorModal from "../../TrapdoorModal";
import VideoMetadata from "../VideoMetadata";

function Trapdoor() {
  let location = useLocation();
  let history = useHistory();

  const itemId = () => {
    var itemId = window.location.pathname
      .split("/")
      .filter((x) => x != "app" && x != "")[0];
    return itemId === undefined ? "borrowed-future" : itemId;
  };

  const [itemMetadata, setItemMetadata] = useState({});
  const [isTrapActivated, setTrapActivated] = useState(false);

  const logger = ApopheniaLogger.initialize({
    appId: LOGGING_APP_ID,
    apiKey: LOGGING_API_KEY,
  });

  const getMetadata = async () => {
    var response = await fetch(`${API_ROOT}/movies/${itemId()}`, {
      method: "GET",
      cache: "no-cache",
    });

    let metadata = {};
    if (response.status == 200) {
      await response.json().then((data) => {
        let durationHours = Math.floor(data.duration / 60);
        let durationMinutes = data.duration % 60;

        metadata.titleImageSrc = data.title_image_source;
        metadata.description = data.description;
        metadata.backgroundImageSrc = data.background_image_source;
        metadata.duration =
          durationHours > 0
            ? `${durationHours}H ${durationMinutes}M`
            : `${durationMinutes}M`;
        metadata.rating = data.rating;
        metadata.releaseYear = data.release_year;
        metadata.title = data.title;
        metadata.trailerUrl = data.trailer_url;

        setItemMetadata(metadata);
      });
    } else {
      history.push("/app");
    }
  };

  useEffect(() => {
    getMetadata();
  }, [location]);

  const openTrapdoor = () => {
    AckbarService.springTheTrap();
    setTrapActivated(true);
  };

  const closeTrapdoor = () => {
    setTrapActivated(false);
  };

  return (
    <div
      className="MoviePlayer"
      style={{
        backgroundImage: "url(" + itemMetadata.backgroundImageSrc + ")",
      }}
    >
      <VideoMetadata {...itemMetadata} playVideo={openTrapdoor} />

      {isTrapActivated && <TrapdoorModal closeTrapdoor={closeTrapdoor} />}
    </div>
  );
}

export default Trapdoor;
